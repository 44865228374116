@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Arial', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/** success payment page element */
.bg {
  background-color: #1727a3;
  max-width: 480px;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px;
  font-family: 'Arial';
  margin-top: 40px;
  border-radius: 18px;
}
.card {
  background-color: #fff;
  width: 100%;
  float: left;
  margin-top: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 80px 30px 25px 30px;
  text-align: center;
  position: relative;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.card__success {
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #1f7c34;
  border: 5px solid #fff;
}
.card__success i {
  color: #fff;
  line-height: 100px;
  font-size: 45px;
}
.card__error {
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #c40808;
  border: 5px solid #fff;
}
.card__error i {
  color: #fff;
  line-height: 100px;
  font-size: 45px;
}
.card__msg {
  text-transform: uppercase;
  color: #55585b;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.card__submsg {
  color: #959a9e;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
}
.card__body {
  background-color: #f8f6f6;
  border-radius: 4px;
  width: 100%;
  margin-top: 30px;
  /*float: left;*/
  box-sizing: border-box;
  padding: 30px;
}
.card__price {
  color: #232528;
  font-size: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.card__price span {
  font-size: 60%;
}
.card__method {
  color: #838383;
  text-transform: uppercase;
  text-align: center;
  font-size: 11px;
  margin-bottom: 5px;
}
.card__payment {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card__credit-card {
  width: 50px;
  display: inline-block;
  margin-right: 15px;
}
.card__card-details {
  display: inline-block;
  text-align: left;
}
.card__card-type {
  text-transform: uppercase;
  color: #232528;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 3px;
}
.card__card-number {
  color: #838890;
  font-size: 12px;
  margin-top: 0px;
}
.card__tags {
  clear: both;
  padding-top: 15px;
}
.card__tag {
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 12px;
  color: #7a7a7a;
}

/** */

.stripeForm {
  /*max-width: 640px;*/
  margin: 18px auto;
  padding: 20px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;
  /*max-width: 400px;*/
  margin: 18px auto;
}

.stripeForm2 {
  background-color: transparent;
  box-shadow: 0;
  margin: 18px auto;
  display: flex;
  height: 94%;

  background-image: url('/public/assets/stripe-startups.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom
}

/*.stripeForm > div.stripeBadge {
  justify-content: center;
  align-items: center;
}*/

img.stripeFormCheckout {
  width: 240px;
  margin-bottom: 12px;
}

.stripeForm > button {
  margin-top: 16px;
  padding: 0 14px;
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  box-shadow:
    0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  /*letter-spacing: 0.025em;*/
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripeForm > button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}

.stripeForm > input,
.stripeForm > .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripeForm > input::placeholder {
  color: #aab7c4;
}

.stripeForm > input:focus,
.stripeForm > .StripeElement--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripeForm > .StripeElement.IdealBankElement,
.stripeForm > .StripeElement.PaymentRequestButton {
  padding: 0;
}

/** */

.cardElement {
  padding: 14px 9px;
  margin-top: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}
